export const msalConfig = {
    auth: {
      // clientId: "eb36ecf6-cff9-48ee-be3c-9ebc7497ca3c",
      clientId: "30a35bb0-87e8-4295-9835-53b9a5d1f2cb",
      authority: "https://login.microsoft.com/ac60b3b8-0994-44b8-aa65-c43116bb0d72",
      redirectUri: process.env.REACT_APP_URL,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };