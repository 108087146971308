import React, { useEffect } from 'react'

import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap'

import BodySecretsMainContribForm from './components/contrib/BodySecretsMainContribForm'
import FilmingTips from './components/contrib/FilmingTips'
import ContributorQuestions from './components/contrib/ContributorQuestions'
import Upload from './components/contrib/Upload'

import { Routes, Route, Navigate } from 'react-router-dom'
import { useLocation } from 'react-router'

export default function App() {

  useEffect(() => {
    //set background colour to match Lambent website
    document.body.style = 'background: #262626;'
  })

  return (
    <Container fluid>
      <LambentNavbar/>
      <RemoveTrailingSlash/>
      <Routes>
        <Route path="/" element={<BodySecretsMainContribForm/>}/>
        <Route path="/tips" element={<FilmingTips/>}/>
        <Route path="/questions/*" element={<ContributorQuestions/>}/>
        <Route path="/upload/*" element={<Upload/>}/>
        </Routes>
      <LambentFooter/>
    </Container>
  )
}

const LambentNavbar = (props) => {
  return (
    <Navbar variant="dark" className="lambent-navbar no-print" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/" className="float-start">
          <img
            src="/lambent-logo.png"
            className="lambent-logo"
            alt="Lambent Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="lambent-nav">
            <Nav.Link href="https://lambent.tv/about/">
              ABOUT
            </Nav.Link>
            <Nav.Link href="https://lambent.tv/productions/">
              PRODUCTIONS
            </Nav.Link>
            <Nav.Link href="https://lambent.tv/people/">
              PEOPLE
            </Nav.Link>
            <Nav.Link href="https://lambent.tv/news/">
              NEWS
            </Nav.Link>
            <Nav.Link href="https://lambent.tv/contact/">
              CONTACT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const LambentFooter = (props) => {
  return (
    <Row className="lambent-footer text-center no-print">
      <hr /><br /><br />
      <Col md={4}>
        <p><a href="tel://+441273789090">+44 (0)1273 789090</a></p>
      </Col>
      <Col md={4}>
        <p>
          Lambent Productions<br />
          96 Church Street<br />
          East Sussex, BN1 1UJ
        </p>
        <p className="lambent-footer-bottom-text">
          <a href="https://lambent.tv/cookiesandterms/" target="_blank" rel="noreferrer">Cookies / Terms</a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://lambentproductions.co.uk/privacy" target="_blank" rel="noreferrer">Privacy</a>
          <br /><br />
          © Lambent Productions 2021
        </p>
      </Col>
      <Col md={4}>
        <p><a href="mailto:info@lambent.tv">info@lambent.tv</a></p>
      </Col>
    </Row>
  )
}

const RemoveTrailingSlash = ({...rest}) => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location && location.pathname?.match('/.*/$')) {
      return <Navigate replace {...rest} to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search
      }}/>
  } else return null
}