import React from 'react'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { msalConfig } from './authConfig'

import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import Col from 'react-bootstrap/esm/Col'
import ListGroup from 'react-bootstrap/esm/ListGroup'
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem'

import { PageLayout } from './PageLayout'
import { SignInButton } from './msal-components'
import { ContributorsTable } from './ContributorsTable'

const msalInstance = new PublicClientApplication(msalConfig)

export default function Admin(props) {
    document.title = "Body Secrets Casting – Admin"
    return (
        <MsalProvider instance={msalInstance}>
            <PageLayout>
                <AuthenticatedTemplate>
                    <ContributorsTable />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NotAuthed />
                </UnauthenticatedTemplate>
            </PageLayout>
        </MsalProvider>
    )
}

const NotAuthed = () => {
    return (
        <Row>
            <Col></Col>
            <Col xs={10} md={8} lg={6} xl={4}>
                <Card style={{ marginTop: "20px" }}>
                    <Card.Header as="h5" bg="dark" text="light">Not Signed In</Card.Header>
                    <ListGroup>
                        <ListGroupItem>This area is for Body Secrets team members only. You must be signed in to view this content.</ListGroupItem>
                        <ListGroupItem style={{ textAlign: "center" }}><SignInButton className="float-end" /></ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col></Col>
        </Row>
    )
}