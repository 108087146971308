import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";

import { ReactComponent as LandscapeImage } from './tips-icons/landscape-image.svg'
import { ReactComponent as CheckLighting } from './tips-icons/check-lighting.svg'
import { ReactComponent as BeQuiet } from './tips-icons/be-quiet.svg'
import { ReactComponent as StayInFrame } from './tips-icons/stay-frame.svg'
import { ReactComponent as KeepItSteady } from './tips-icons/keep-steady.svg'
import { ReactComponent as RearCamera } from './tips-icons/rear-camera.svg'
import { ReactComponent as CloseButton } from './tips-icons/close-button.svg'

const FilmingTips = (props) => {
    return (
        <Container className="lambent-casting-container">
            <Row>
                <Col>
                    <h1 hidden={props.hideTitle}>FILMING TIPS</h1>
                    <p>You can record your video on just about any device you own that has a camera: your phone, tablet, laptop, or computer with a webcam will all work. Use the tips below to get the best possible video on whatever camera you decide to use.</p>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <LandscapeImage className="tips-image" />
                </Col>
                <Col md={8}>
                    <h3>FILM IN LANDSCAPE</h3>
                    <p>If you're filming on your phone or tablet, remember to flip it on its side before recording your video.</p>
                    <p className="filming-tips-sub">Our programme will be shown in landscape, so your clip will look a lot better if you record it that way, too.</p>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <CheckLighting className="tips-image" />
                </Col>
                <Col md={8}>
                    <h3>CHECK YOUR LIGHTING</h3>
                    <p>Make sure there aren't any <u>bright lights or windows directly behind you</u> when recording your video.</p>
                    <p className="filming-tips-sub">If you can, <u>sit facing a window, or in front of a soft lamp</u>, to make sure you're the brightest thing in frame.</p>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <BeQuiet className="tips-image" />
                </Col>
                <Col md={8}>
                    <h3>MAKE SURE IT'S QUIET</h3>
                    <p>Try to record your video in a quiet room, <u>turning off anything else in the background such as TVs or music</u>.</p>
                    <p className="filming-tips-sub">If anything interrupts you part way through, just <u>wait until it stops, then start your sentence again</u>.</p>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <StayInFrame className="tips-image" />
                </Col>
                <Col md={8}>
                    <h3>STAY IN FRAME</h3>
                    <p>Make sure you're positioned in the <u>middle of your screen, with the top of your head visible.</u></p>
                    <p className="filming-tips-sub">It's best if you position yourself from the middle of your chest up, as shown in the picture.</p>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <KeepItSteady className="tips-image" />
                </Col>
                <Col md={8}>
                    <h3>KEEP IT STEADY</h3>
                    <p>If you can, <u>place your phone/laptop/tablet on a solid surface</u> when filming, perhaps propped up by something like a box or a stack of books.</p>
                    <p className="filming-tips-sub">This makes sure your video isn't shaky, and means it's much easier for us to use.</p>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <RearCamera className="tips-image" />
                </Col>
                <Col md={8}>
                    <h3>USE THE REAR CAMERA</h3>
                    <p>The rear camera of your phone or tablet is much better quality than the front – if you feel comfortable doing so, use that one instead.</p>
                    <p className="filming-tips-sub">It might help if you ask someone else to look at your phone/tablet make sure you're in the middle of the screen.</p>
                </Col>
                <hr />
            </Row>
        </Container>
    )
}

export const FilmingTipsModal = (props) => {
    return (
        <Modal
            show={props.shouldShow}
            onHide={props.onHide}
            className="tips-modal"
            size="lg"
        >
            <FilmingTipsModalHeader onHide={props.onHide} />
            <Modal.Body>
                <div id="spacer" style={{height: "70px"}}/>
                <FilmingTips hideTitle={true} />
            </Modal.Body>
        </Modal>
    )
}

const FilmingTipsModalHeader = (props) => {
    return (
        <div className="tips-modal-header">
            <Container fluid>
                <h1>FILMING TIPS</h1>
            </Container>
            <CloseButton
                className="tips-modal-close-button"
                onClick={() => props.onHide()}
            />
        </div>
    )
}

export default FilmingTips