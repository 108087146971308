import React, { useEffect, useState } from 'react'

import { BodySecretsAPI, useAdminAuth } from '../bs-api'

import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Table from 'react-bootstrap/esm/Table'
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/esm/Modal'
import Form from 'react-bootstrap/esm/Form'
import Spinner from 'react-bootstrap/esm/Spinner'

import Select from 'react-select'
import { MdOutlineRefresh } from "react-icons/md"

import { useLocation, useNavigate } from 'react-router-dom'

import { EditableTickList, QuickPopup, conditionValueToLabel, conditionsList } from '../formElements'

export const ContributorsTable = (props) => {
    const { adminApi } = useAdminAuth()

    const { hash } = useLocation()

    var [contributorData, setContributorData] = useState([])
    var [filteredContributorData, setFilteredContributorData] = useState([])
    var [selectedContributor, setSelectedContributor] = useState(null)

    var [filterSearch, setFilterSearch] = useState(null)
    var [filterConditions, setFilterConditions] = useState(null)
    var [filterStrand, setFilterStrand] = useState(null)

    var [isUpdating, setIsUpdating] = useState(false)

    var [contributorDataShouldUpdate, setContributorDataShouldUpdate] = useState(true)

    // Component load
    useEffect(() => {
    }, [])

    // Hash change
    useEffect(() => {
        if (hash.length > 1) {
            setSelectedContributor(filteredContributorData.find(x => x.id === hash.replace("#", "")) || null)
        }
    }, [hash, filteredContributorData])

    // Filter change
    useEffect(() => {
        if (!contributorData) { return }
        if (filterConditions?.length === 0) setFilterConditions(null)
        if (filterStrand?.length === 0) setFilterStrand(null)
        if (filterSearch?.length === 0) setFilterSearch(null)

        var filteredData = contributorData

        // Do the conditions first
        if (filterConditions) {
            var conditionValues = []

            filterConditions.forEach(condition => conditionValues.push(condition.value))

            filteredData = filteredData.filter((contrib) => {
                var exists = false
                contrib.conditions.forEach((condition) => {
                    if (conditionValues.includes(condition)) {
                        exists = true
                        return
                    }
                })
                return exists
            })
        }

        // Then the strand
        if (filterStrand) {
            var strandValues = []

            filterStrand.forEach(s => strandValues.push(s.value))

            filteredData = filteredData.filter((contrib) => {
                var exists = false

                contrib.strands.forEach((strand) => {
                    if (strandValues.includes(strand)) {
                        exists = true
                        return
                    }
                })

                return exists
            })
        }

        // Now search the remainder
        if (filterSearch) {
            filteredData = filteredData.filter((contrib) => {
                let testString = `${contrib.fullName || ""} ${contrib.location || ""} ${contrib.age || ""} ${contrib.message || ""} ${contrib.notes || ""} ${contrib.phone || ""}`.toLowerCase()

                return testString.includes(filterSearch.toLowerCase())
            })
        }

        filteredData.sort((one, two) => {
            if (one.isNew || two.isNew) {
                if (one.isNew && !two.isNew) {
                    return false
                } else if (!one.isNew && two.isNew) {
                    return true
                }
            }
            if (one.hasNewInfo || two.hasNewInfo) {
                if (one.hasNewInfo && !two.hasNewInfo) {
                    return false
                } else if (!one.hasNewInfo && two.hasNewInfo) {
                    return true
                }
            }
            if (one.fullName !== two.fullName) {
                let oneSplit = one.fullName.split(' ')
                let twoSplit = two.fullName.split(' ')

                if (oneSplit[0] !== twoSplit[0]) {
                    return oneSplit[0].localeCompare(twoSplit[0])
                } else {
                    if (oneSplit[1] && twoSplit[1]) {
                        if (oneSplit[1] !== twoSplit[1]) {
                            return oneSplit[1].localeCompare(twoSplit[1])
                        } else {
                            return one.fullName.localeCompare(two.fullName)
                        }
                    } else if (oneSplit[1]) {
                        return false
                    } else {
                        return true
                    }
                }
            } else {
                return one.id > two.id
            }
        })

        setFilteredContributorData(filteredData)

    }, [filterSearch, filterConditions, filterStrand, contributorData])

    // Update all contributors
    useEffect(() => {
        if (contributorDataShouldUpdate) {
            setIsUpdating(true)

            adminApi?.getContributors()
                .then((res) => {
                    setContributorData(res.data.contributors)
                    setContributorDataShouldUpdate(false)
                    setIsUpdating(false)
                })
                .catch((err) => {
                    alert("Error getting contributor data. Please contact Chloë\n\n" + err)
                    setContributorDataShouldUpdate(false)
                    setIsUpdating(false)
                })
        }
    }, [contributorDataShouldUpdate, adminApi])

    // Update specific contributor
    function updateContributor(contribId) {
        // TODO: Make this update a contributor with a specific ID. If it's a new contributor, add all of their data to the contributor data
    }

    return (
        <>
            <ContributorFilterView
                search={filterSearch}
                setSearch={setFilterSearch}
                conditions={filterConditions}
                setConditions={setFilterConditions}
                strands={filterStrand}
                setStrands={setFilterStrand}
                shouldUpdate={contributorDataShouldUpdate}
                setShouldUpdate={setContributorDataShouldUpdate}
                isUpdating={isUpdating}
            />
            <ContributorDetailsView
                selectedContributor={selectedContributor}
                newContributor={hash.replace("#", "") === "new" ? true : false}
                shouldShow={hash.length > 2}
                updateMethod={updateContributor}
                // TODO: Make this come back with the selected contributor and only update them
                onSave={() => setContributorDataShouldUpdate(true)}
            />

            <Table hover className='contrib-table .g-0'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Location</th>
                        <th>Condition(s)</th>
                        <th>Video</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {!filteredContributorData || filteredContributorData.length === 0 ?
                        isUpdating ?
                            <tr><td colSpan="6"><Container>Loading Contributors</Container></td></tr> :
                            <tr><td colSpan="6"><Container>No Contributors</Container></td></tr> :
                        filteredContributorData.map((contrib) => { return (<ContributorRow key={contrib.id} contrib={contrib} />) })}
                </tbody>
            </Table>
        </>
    )
}

const ContributorRow = (props) => {
    let navigate = useNavigate()
    //see if message is too long and truncate if is
    if (props.contrib.message?.length > 200) {
        props.contrib.truncMessage = props.contrib.message.substring(0, 200) + "… "
    }

    return (
        <tr onClick={() => navigate('#' + props.contrib.id)} className={`contrib-row${props.contrib.isNew ? ' is-new' : props.contrib.hasNewInfo ? ' new-info' : null}`}>
            <td>{props.contrib.id}</td>
            <td
                style={{ minWidth: `${props.contrib.fullName.length * 0.7 < 12 ? props.contrib.fullName.length * 0.7 : 12}em` }}>
                {props.contrib.fullName}
            </td>
            <td>{props.contrib.age}</td>
            <td>{props.contrib.location}</td>
            <td>{props.contrib.conditions && props.contrib.conditions?.length > 0 ?
                props.contrib.conditions.length === 1 ?
                    conditionValueToLabel(props.contrib.conditions[0], true) :
                    props.contrib.conditions.map((cs, i) => {
                        return (<p key={`${props.contrib.id}-${i}`} style={{ margin: "2px" }}>{conditionValueToLabel(cs, true)}</p>)
                    }) :
                "N/A"}</td>
            <td>{Object.keys(props.contrib.videos)?.length > 0 ? "Yes" : "No"}</td>
            {/* <td className={props.contrib.videoRelease ? "contrib-dl-button" : ""}>
                {props.contrib.videoRelease ? <a href={props.contrib.videoRelease}>⬇</a> : "No"}
            </td> */}
            {/* <td className='contrib-message'>{props.contrib.truncMessage ? props.contrib.truncMessage : props.contrib.message}{props.contrib.truncMessage ? <a href={'#' + props.contrib.id}> Read more</a> : null}</td> */}
            <td className="contrib-message">{props.contrib.notes}</td>
        </tr>
    )
}

const ContributorFilterView = (props) => {
    return (
        <Container fluid className="filters">
            <Row>
                <Col className="d-grid gap-2" lg={2} md={4}>
                    <NewContributorButton />
                </Col>
                <Col lg={3} md={7}>
                    <Form>
                        <Form.Control
                            type="search"
                            placeholder="Search by name, location, notes, etc"
                            value={props.search || ""}
                            onChange={(e) => props.setSearch(e.target.value)}
                        />
                    </Form>
                </Col>
                <Col lg={3} md={6} className="col-top-margin">
                    <ContributorFilterConditions
                        conditions={props.conditions}
                        setConditions={props.setConditions}
                    />
                </Col>
                <Col lg={3} md={6} className="col-top-margin">
                    <Row>
                        <ContributorFilterStrand
                            strands={props.strands}
                            setStrands={props.setStrands}
                        />
                    </Row>
                </Col>
                <Col lg={1} md={1} className="text-center">
                    <RefreshButton
                        shouldUpdate={props.shouldUpdate}
                        setShouldUpdate={props.setShouldUpdate}
                        isUpdating={props.isUpdating}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const NewContributorButton = (props) => {
    let navigate = useNavigate()
    return (
        <Button onClick={() => navigate('#new')}>New Contributor</Button>
    )
}

const RefreshButton = (props) => {
    if (!props.isUpdating) {
        return (
            <MdOutlineRefresh
                as={Button}
                className="refresh-button"
                size="38"
                onClick={() => props.setShouldUpdate(true)}
            />
        )
    } else {
        return (
            <Spinner animation="grow" variant="primary" />
        )
    }
}

const ContributorFilterConditions = (props) => {
    var conditionOptions = []

    conditionsList.forEach((condition) => {
        conditionOptions.push({
            value: condition.value,
            label: condition.backend_label
        })
    })

    function onChange(e) {
        props.setConditions(Array.from(e.values()))
    }

    return (
        <Select
            options={conditionOptions}
            isMulti
            value={props.conditions}
            onChange={onChange}
            placeholder="Filter by conditions"
        />
    )
}

const ContributorFilterStrand = (props) => {
    let strandOptions = [
        {
            value: "a",
            label: "A"
        },
        {
            value: "b",
            label: "B"
        },
        {
            value: "c",
            label: "C"
        }
    ]

    function onChange(e) {
        props.setStrands(Array.from(e.values()))
    }

    return (
        <Select
            options={strandOptions}
            isMulti
            value={props.strands}
            onChange={onChange}
            placeholder="Filter by strand"
        />
    )
}

const ContributorDetailsView = (props) => {
    const { adminApi } = useAdminAuth()

    let navigate = useNavigate()

    const [cName, setCName] = useState("")
    const [cLocation, setCLocation] = useState("")
    const [cAge, setCAge] = useState("")
    const [cEmail, setCEmail] = useState("")
    const [cPhone, setCPhone] = useState("")
    const [cConditions, setCConditions] = useState([])
    const [cStrands, setCStrands] = useState([])
    const [cMessage, setCMessage] = useState("")
    const [cNotes, setCNotes] = useState("")

    const [isEditing, setIsEditing] = useState(false)
    const [hasChanges, setHasChanges] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const [showDiscardChangesWarning, setShowDiscardChangesWarning] = useState(false)
    const [discardChangesWarningCancelMethod, setDiscardChangesWarningCancelMethod] = useState(null)
    const [discardChangesWarningDiscardMethod, setDiscardChangesWarningDiscardMethod] = useState(null)

    const [showVideoURLPopup, setShowVideoURLPopup] = useState(false)

    // Set fields when selected contributor changes
    useEffect(() => {
        resetContributorToDefaults()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedContributor])

    // Check for changes against original
    useEffect(() => {
        if (!props.newContributor && props.selectedContributor) {
            setHasChanges([
                { new: cName, original: props.selectedContributor?.fullName },
                { new: cLocation, original: props.selectedContributor?.location },
                { new: cAge, original: props.selectedContributor?.age },
                { new: cEmail, original: props.selectedContributor?.email },
                { new: cPhone, original: props.selectedContributor?.phone },
                { new: cMessage, original: props.selectedContributor?.message },
                { new: cNotes, original: props.selectedContributor?.notes },
                { new: cStrands, original: props.selectedContributor?.strands },
                { new: cConditions, original: props.selectedContributor?.conditions }
            ].some(x => itemsAreDifferent(x)))
        } else {
            setHasChanges([cName, cLocation, cAge, cEmail, cPhone, cConditions, cStrands, cMessage, cNotes].some(x => x.length > 0))
        }
    }, [cName, cLocation, cAge, cEmail, cPhone, cConditions, cStrands, cMessage, cNotes, props.selectedContributor, props.newContributor])

    // Always be editing if it's a new contributor
    useEffect(() => {
        if (props.newContributor && !isEditing) {
            setIsEditing(true)
        }
    }, [props.newContributor, isEditing])

    useEffect(() => {
        if (props?.selectedContributor?.isNew) {
            adminApi.updateContributor({ id: props.selectedContributor.id, isNew: false })
                .then(props.onSave())
        }
        if (props?.selectedContributor?.hasNewInfo) {
            adminApi.updateContributor({ id: props.selectedContributor.id, hasNewInfo: false })
                .then(props.onSave())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedContributor])

    function close() {
        setIsEditing(false)
        navigate('#')
    }

    function closePressed() {
        if (isSaving) {
            return
        }

        if (hasChanges && isEditing) {
            setDiscardChangesWarningCancelMethod(() => () => setShowDiscardChangesWarning(false))

            setDiscardChangesWarningDiscardMethod(() => () => {
                resetContributorToDefaults()
                setIsEditing(false)
                setShowDiscardChangesWarning(false)
                close()
            })

            setShowDiscardChangesWarning(true)
        } else {
            close()
        }
    }

    function editSavePressed() {
        // TODO: Actually save the contributor before closing and cause an update on the table
        if (!isEditing) {
            setIsEditing(true)
        } else {
            var dataToPost = {
                fullName: cName,
                location: cLocation,
                age: cAge,
                email: cEmail,
                phone: cPhone,
                message: cMessage,
                conditions: cConditions,
                strands: cStrands,
                notes: cNotes,
                isNew: false
            }

            setIsSaving(true)

            if (props.newContributor) {
                adminApi.newContributor(dataToPost)
                    .then((res) => {
                        props.onSave()
                        resetContributorToDefaults()
                        close()
                        setIsSaving(false)
                    })
                    .catch((err) => {
                        alert("Something went wrong:\n\n" + err)
                        setIsSaving(false)
                    })
            } else {
                dataToPost.id = props.selectedContributor.id
                adminApi.updateContributor(dataToPost)
                    .then((res) => {
                        props.onSave()
                        close()
                        setIsSaving(false)
                    })
                    .catch((err) => {
                        alert("Something went wrong:\n\n" + err)
                        setIsSaving(false)
                    })
            }
        }
    }

    function cancelPressed() {
        if (isSaving) {
            return
        }

        if (hasChanges) {
            setDiscardChangesWarningCancelMethod(() => () => setShowDiscardChangesWarning(false))

            setDiscardChangesWarningDiscardMethod(() => () => {
                resetContributorToDefaults()
                setIsEditing(false)
                setShowDiscardChangesWarning(false)
            })

            setShowDiscardChangesWarning(true)
        } else {
            setIsEditing(false)
        }
    }

    // Request a URL that the contributor can use to send in clips
    function requestVideoURL() {
        setShowVideoURLPopup(true)
    }

    if (!props.selectedContributor && !props.newContributor) return null

    return (
        <>
            <Modal
                show={props.shouldShow}
                fullscreen='md-down'
                dialogClassName="modal-90w"
                onHide={closePressed}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.newContributor ? "Add New Contributor" : `${props.selectedContributor?.fullName} (${props.selectedContributor.id})` || "No Contributor Selected"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={7} sm={12}>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Name
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control
                                                type="text"
                                                value={cName}
                                                onChange={(e) => setCName(e.target.value)}
                                                plaintext={!isEditing}
                                                disabled={!isEditing || isSaving}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Age
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control
                                                type="text"
                                                value={cAge}
                                                onChange={(e) => setCAge(e.target.value)}
                                                plaintext={!isEditing}
                                                disabled={!isEditing || isSaving}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Location
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control
                                                type="text"
                                                value={cLocation}
                                                onChange={(e) => setCLocation(e.target.value)}
                                                plaintext={!isEditing}
                                                disabled={!isEditing || isSaving}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Email Address
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control
                                                type="email"
                                                value={cEmail}
                                                onChange={(e) => setCEmail(e.target.value)}
                                                plaintext={!isEditing}
                                                disabled={!isEditing || isSaving}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Phone Number
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control
                                                type="phone"
                                                value={cPhone}
                                                onChange={(e) => setCPhone(e.target.value)}
                                                plaintext={!isEditing}
                                                disabled={!isEditing || isSaving}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Conditions
                                        </Form.Label>
                                        <Col sm={8}>
                                            <EditableTickList as={Form.Control}
                                                isBackend={true}
                                                values={cConditions}
                                                updateMethod={setCConditions}
                                                isEditing={isEditing}
                                                isSaving={isSaving}
                                                kind="conditions"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Strands
                                        </Form.Label>
                                        <Col sm={8}>
                                            <EditableTickList as={Form.Control}
                                                isBackend={true}
                                                values={cStrands}
                                                updateMethod={setCStrands}
                                                kind="strands"
                                                isEditing={isEditing}
                                                isSaving={isSaving}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={2}>
                                            Notes
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control
                                                value={cNotes}
                                                onChange={(e) => setCNotes(e.target.value)}
                                                as="textarea"
                                                rows={3}
                                                disabled={!isEditing}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={5} sm={12}>
                                <Row>
                                    <p className="contribl-modal-message-header"><strong>Contributor Message</strong></p>
                                    <p className="contrib-modal-message">{cMessage || "No Message"}</p>
                                </Row>
                                {(props.selectedContributor !== null && Object.keys(props.selectedContributor.videos)?.length > 0) ?
                                    <Row className="contrib-modal-videos-container">
                                        <Col className="contrib-modal-videos">
                                            <p className="contribl-modal-message-header"><strong>Videos:</strong></p>
                                            {
                                                Object.keys(props.selectedContributor.videos).map(videoTitle => {
                                                    return <p className="video-item" key={videoTitle}><b>{videoTitle}</b><br />{props.selectedContributor.videos[videoTitle]}</p>
                                                })
                                            }
                                        </Col>
                                    </Row> :
                                    null
                                }

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col xs={6}>
                                {isEditing ? <CancelButton /> : <GenerateURLButton />}
                            </Col>
                            <Col xs={6}>
                                <Button
                                    variant="primary"
                                    onClick={editSavePressed}
                                    disabled={isEditing && !hasChanges}
                                    className="float-end"
                                    hidden={isSaving}
                                >
                                    {isEditing ? "Save" : "Edit"}
                                </Button>
                                <Spinner
                                    animation="grow"
                                    variant="primary"
                                    className="float-end"
                                    hidden={!isSaving}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <QuickPopup
                title="Unsaved Changes"
                shouldShow={showDiscardChangesWarning}
                message="You have unsaved changes for this contributor, are you sure you want to discard them?"
                leftButton={{
                    disabled: false,
                    onClick: discardChangesWarningCancelMethod,
                    title: "Cancel",
                    variant: "secondary"
                }}
                rightButton={{
                    disabled: false,
                    onClick: discardChangesWarningDiscardMethod,
                    title: "Discard",
                    variant: "danger"
                }}
            />
            <QuickPopup
                title="Video URL"
                message="In the future, you'll be able to use this to generate a link where a contributor can upload new videos to us. Unfortunately this has not been set up yet, sorry! You can send them to lambent.wetransfer.com where they can upload a video to us instead."
                shouldShow={showVideoURLPopup}
                rightButton={{
                    disabled: false,
                    title: "Okay",
                    onClick: () => setShowVideoURLPopup(false)
                }}
            />
        </>
    )

    function CancelButton() {
        // Don't show the initial cancel button for a new contributor
        if (!hasChanges && props.newContributor) return (null)

        return (<Button variant={hasChanges ? "danger" : "secondary"} onClick={cancelPressed} className="float-start" disabled={isSaving}>Cancel</Button>)
    }

    function GenerateURLButton() {
        return (<Button variant="info" onClick={requestVideoURL} className="float-start">Get Video URL</Button>)
    }

    function resetContributorToDefaults() {
        setCName(props.selectedContributor?.fullName || "")
        setCLocation(props.selectedContributor?.location || "")
        setCAge(props.selectedContributor?.age || "")
        setCPhone(props.selectedContributor?.phone || "")
        setCEmail(props.selectedContributor?.email || "")
        setCConditions(props.selectedContributor?.conditions || [])
        setCStrands(props.selectedContributor?.strands || [])
        setCMessage(props.selectedContributor?.message || "")
        setCNotes(props.selectedContributor?.notes || "")
    }

    function itemsAreDifferent(item) {
        item.original = item.original || ""
        item.new = item.new || ""

        if (Array.isArray(item.new)) {
            return item.new.length === item.original.length ? item.new.some(x => !item.original.includes(x)) : true
        } else {
            // simple comparison because it should just be a string
            return item.original !== item.new
        }
    }
}