import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Modal, Button, Spinner, Form, InputGroup } from 'react-bootstrap'
import Select from 'react-select'

import conditions from './conditions.json'

import { BodySecretsAPI } from './bs-api'

const api = new BodySecretsAPI()

export const conditionsList = conditions

export const EditableTickList = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([])

    useEffect(() => {
        var newSelectedOptions = []

        if (props.values?.length > 0) {
            switch (props.kind) {
                case "conditions":
                    props.values.forEach((val) => {
                        let newObj = conditionsList.find(x => x.value === val)
                        newSelectedOptions.push({
                            value: newObj?.value || "",
                            label: newObj?.backend_label || ""
                        })
                    })
                    break
                case "strands":
                    props.values.forEach((val) => {
                        newSelectedOptions.push({
                            value: val,
                            label: val.toUpperCase()
                        })
                    })
                    break
                default:
                    newSelectedOptions = []

            }
        }

        setSelectedOptions(newSelectedOptions)
    }, [props.values, props.kind])

    function onChange(e) {
        let newValues = []
        Array.from(e.values()).forEach((val) => {
            newValues.push(val.value)
        })
        props.updateMethod(newValues)
    }

    var options = []

    switch (props.kind) {
        case "conditions":
            conditionsList.forEach((condition) => {
                options.push({
                    value: condition.value,
                    label: props.isBackend ? condition.backend_label : condition.contrib_label
                })
            })
            break
        case "strands":
            options = [
                {
                    value: "a",
                    label: "A"
                },
                {
                    value: "b",
                    label: "B"
                },
                {
                    value: "c",
                    label: "C"
                }
            ]
            break
        default:
            options = [{ value: "na", label: "Invalid Kind" }]
    }

    return (
        <Select
            isMulti
            className="lambent-casting-form-select"
            options={options}
            value={selectedOptions}
            onChange={onChange}
            disabled={props.isSaving}
        />
    )
}

export const QuickPopup = (props) => {
    return (
        <Modal
            show={props.shouldShow}
            onHide={props.onHide}
            backdrop="static"
            keyboard="false"
            centered
        >
            {props.title ? <Modal.Header><Modal.Title>{props.title}</Modal.Title></Modal.Header> : null}
            <Modal.Body>{props.message || "No message set"}</Modal.Body>
            <Modal.Footer>
                <Container>
                    {props.leftButton ?
                        <Button
                            variant={props.leftButton.variant || "secondary"}
                            onClick={props.leftButton.onClick}
                            disabled={props.leftButton.disabled !== undefined ? props.leftButton.disabled : true}
                            className="float-start">
                            {props.leftButton.title || "Cancel"}
                        </Button> :
                        null
                    }
                    {props.rightButton ?
                        <Button
                            variant={props.rightButton.variant || "primary"}
                            onClick={props.rightButton.onClick}
                            disabled={props.rightButton.disabled !== undefined ? props.rightButton.disabled : true}
                            className="float-end">
                            {props.rightButton.title || "Done"}
                        </Button> :
                        null
                    }
                </Container>
            </Modal.Footer>


        </Modal>
    )
}

export const UploadModal = (props) => {
    const [hasChanges, setHasChanges] = useState(false)
    const [isComplete, setIsComplete] = useState(false)

    const [showDiscardChangesWarning, setShowDiscardChangesWarning] = useState(false)
    const [discardChangesWarningCancelMethod, setDiscardChangesWarningCancelMethod] = useState(null)
    const [discardChangesWarningDiscardMethod, setDiscardChangesWarningDiscardMethod] = useState(null)

    function hide() {
        if (hasChanges && !isComplete) {
            setShowDiscardChangesWarning(true)
            setDiscardChangesWarningCancelMethod(() => () => setShowDiscardChangesWarning(false))
            setDiscardChangesWarningDiscardMethod(() => () => {
                setShowDiscardChangesWarning(false)
                setIsComplete(false)
                setHasChanges(false)
                props.onHide()
            })
        } else {
            props.onHide()
        }
    }

    function formUpdated(changes) {
        setHasChanges(changes)
    }

    function uploadComplete(res, error) {
        if (res) {
            setIsComplete(true)
        }
    }

    return (
        <>
            <Modal
                show={props.shouldShow}
                onHide={hide}
                className="dark-modal"
                variant="dark"
                size="lg"
                fullscreen="lg-down"
                centered
            >
                <Modal.Header closeButton><h4>{isComplete ? "THANK YOU" : "UPLOAD FILES"}</h4></Modal.Header>
                <Modal.Body>
                    {
                        props.showRelease ?
                            <UploadFileWithRelease
                                questionType={props.questionType}
                                onUpdate={formUpdated}
                                finishedUpload={uploadComplete}
                                contribId={props.contribId}
                            /> :
                            <UploadFile
                                questionType={props.questionType}
                                onUpdate={formUpdated}
                                finishedUpload={uploadComplete}
                                contribId={props.contribId}
                            />
                    }
                </Modal.Body>
            </Modal>
            <QuickPopup
                title="Unsaved Changes"
                shouldShow={showDiscardChangesWarning}
                message="You have unsaved changes, are you sure you want to discard them?"
                leftButton={{
                    disabled: false,
                    onClick: discardChangesWarningCancelMethod,
                    title: "Cancel",
                    variant: "secondary"
                }}
                rightButton={{
                    disabled: false,
                    onClick: discardChangesWarningDiscardMethod,
                    title: "Discard",
                    variant: "danger"
                }}
            />
        </>
    )
}

export const UploadFile = (props) => {
    const [cName, setCName] = useState("")
    const [cId, setCId] = useState("")
    const [cDescription, setCDescription] = useState("")
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false)

    const [isSaving, setIsSaving] = useState(false)
    const [uploadMessage, setUploadMessage] = useState(null)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const [cSelectedFiles, setCSelectedFiles] = useState([])

    const [nameRequired, setNameRequired] = useState(true)
    const [idRequired, setIdRequired] = useState(true)
    const [alreadySetId, setAlreadySetId] = useState(false)

    const [hasChanges, setHasChanges] = useState(false)
    const [validated, setValidated] = useState(false)
    const [videoIsValid, setVideoIsValid] = useState(true)
    const [showIdOrNameValidation, setShowIdOrNameValidation] = useState(false)

    const [ignoreId, setIgnoreId] = useState(false)

    // Notify parents that changes have occurred
    useEffect(() => {
        props.onUpdate(hasChanges)
        if (props.contribId && cId.length === 0 && !alreadySetId) {
            setCId(props.contribId)
            setAlreadySetId(true)
        }
    }, [hasChanges, props, cId, alreadySetId])

    // Make this component manageable
    useEffect(() => {
        if (typeof props.cName !== 'undefined' && (props.cName.length !== cName.length || !props.cName.includes(cName))) {
            setCName(props.cName)
        }

        if (typeof props.cId !== 'undefined' && (props.cId.length !== cId.length || !props.cId.includes(cId))) {
            setCId(props.cId)
        }
    }, [props, cId, cName])

    // Check for changes
    useEffect(() => {
        if (cName && cName.length > 0) {
            setHasChanges(true)
        } else if (cId && cId.length > 0) {
            setHasChanges(true)
        } else if (cSelectedFiles && cSelectedFiles.length > 0) {
            setHasChanges(true)
        } else {
            setHasChanges(false)
        }

        var hasName = false
        var hasId = false

        if (cName && cName.length > 0) {
            hasName = true
        }

        if (cId && cId.length > 0) {
            hasId = true
        }

        setNameRequired(!hasId)
        setIdRequired(!hasName)

        setShowIdOrNameValidation(validated && !hasId && !hasName)

    }, [cName, cId, cSelectedFiles, validated])

    const fileSelected = (e) => {
        if (e.target.files?.length > 0) {
            var fileIsTooBig = false

            for (var i = 0; i < e.target.files.length; i++) {
                console.log("File size: " + e.target.files[i].size)
                if (e.target.files[i].size > 1024 ** 3 * 5) {
                    fileIsTooBig = true
                }
            }

            if (fileIsTooBig) {
                console.log("too big")
                setVideoIsValid(false)
                e.target.setCustomValidity('too-big')
            } else {
                console.log("just right")
                setVideoIsValid(true)
                e.target.setCustomValidity('')
            }

            setCSelectedFiles(e.target.files)
        } else {
            setCSelectedFiles(null)
        }
    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);

        var contribToSubmit = {
            "agreedToPrivacy": agreedToPrivacy
        }

        if (cName && cName.length > 1) {
            contribToSubmit.fullName = cName
        }

        if (form.checkValidity() === true) {
            if (cSelectedFiles) {
                let submitId = cId && cId.length > 3 ? cId : null
                setIsSaving(true)
                api.uploadVideos(cSelectedFiles, cName ? { fullName: cName } : null, ignoreId ? null : submitId, props.questionType, (message) => {
                    console.log("Message: " + message)
                    setUploadMessage(message)
                })
                    .then((res) => {
                        let contribId = res.contribId
                        let videosToAdd = []

                        setUploadMessage('Sending your information (final step!)')
                        console.log("Upload result: " + JSON.stringify(res))

                        res.keys.forEach((key) => {
                            let newKey = key.substring(key.lastIndexOf('/' + 1))
                            console.log("New key: " + newKey)

                            let newVideo = {
                                title: newKey,
                                description: cDescription ? cDescription : props.questionType ? "Answers for questions relating to " + props.questionType : "No description given"
                            }
                            videosToAdd.push(newVideo)
                        })

                        console.log('Submitting videos: ' + JSON.stringify(videosToAdd))

                        api.addVideos(contribId, videosToAdd)
                            .then((res) => {
                                console.log("We did it! " + JSON.stringify(res))
                                setUploadMessage('Upload complete!')
                                setIsSaving(false)
                                setHasSubmitted(true)
                                props.finishedUpload(res, null)
                            })
                            .catch((err) => {
                                setIsSaving(false)
                                setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                                alert('An error occurred submitting your information, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
                                props.finishedUpload(null, err)
                            })
                    })
                    .catch((err) => {
                        setIsSaving(false)
                        if (!ignoreId && cName?.length > 1) {
                            // We haven't tried uploading without the ID so let's do that
                            setIgnoreId(true)
                            handleSubmit(e)
                        } else {
                            if (cId && cId.length > 3 && !ignoreId && (!cName || cName?.length < 2)) {
                                setUploadMessage('Unable to submit using ID. Please try entering your full name and retry.')
                                props.finishedUpload(null, err)
                            } else {
                                setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                                alert("Something went wrong here: " + JSON.stringify(err))
                                props.finishedUpload(null, err)
                            }
                        }
                    })
            }
        }
    }

    return (
        <>
            <Container className={`lambent-casting-container crossfade-wrapper ${hasSubmitted ? " cf-hidden" : ""}`}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="lambent-casting-form">
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Full Name
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                required={nameRequired}
                                id="name"
                                type="text"
                                value={cName}
                                onChange={(e) => setCName(e.target.value)}
                                disabled={isSaving}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Contributor ID<span className="form-red-star"></span>
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                required={idRequired}
                                id="id"
                                type="text"
                                minLength={4}
                                value={cId}
                                onChange={(e) => setCId(e.target.value.toUpperCase())}
                                disabled={isSaving}
                            />
                            <Form.Text className="form-red-star" hidden={!showIdOrNameValidation}>
                                Please enter either your full name or your contributor ID
                            </Form.Text>
                            <Form.Text className="text-muted">
                                <p style={{ marginTop: "5px" }}>If you've already been speaking to a member of our team, please ask what your contributor ID is before submitting your video(s).</p>
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Videos
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="file"
                                required
                                onChange={fileSelected}
                                capture={false}
                                multiple
                                accept="video/*,video/mp4,video/x-m4v"
                                disabled={isSaving}
                                isValid={videoIsValid} />
                            <Form.Text className="form-red-star" hidden={videoIsValid}>
                                Video file must be less than 5GB in size
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} hidden={!props.showDescription}>
                        <Form.Label column sm={4}>
                            Video Description
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                style={{ zIndex: 0 }}
                                value={cDescription}
                                onChange={(e) => setCDescription(e.target.value)}
                                as="textarea"
                                disabled={isSaving}
                                rows={3}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={4} xs={0}></Col>
                        <Col sm={8} xs={12}>
                            <Row>
                                <Form.Label column md={1} sm={2} xs={3}>
                                    <Form.Check
                                        required
                                        type="switch"
                                        id="privacy-switch"
                                        checked={agreedToPrivacy}
                                        isValid={agreedToPrivacy}
                                        onChange={(e) => setAgreedToPrivacy(e.target.checked)}
                                    />
                                </Form.Label>
                                <Form.Label column md={11} sm={10} xs={9} className="lambent-casting-form-terms-and-conditions">
                                    I agree to Lambent Productions Ltd. processing my personal information in accordance with their <a href="https://lambent.tv/privacy/" target="_blank" rel="noreferrer">privacy policy</a>.
                                    <p className='invalid-feedback-custom' hidden={!validated || agreedToPrivacy}>Please agree before submitting the form</p>
                                </Form.Label>
                            </Row>
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col md={4} sm={0} />
                        <Col md={8} sm={12}>
                            <Button
                                type="submit"
                                className="float-start"
                                disabled={isSaving}>
                                {
                                    !isSaving ? "Submit" : "Submit"
                                }
                            </Button>
                            <Spinner
                                className="float-start align-middle"
                                hidden={!isSaving}
                                animation="border"
                                variant="secondary"
                                style={{ marginTop: "3px", marginLeft: "10px" }}
                            />
                            <div
                                className="float-start"
                                hidden={!uploadMessage}
                                style={{ marginTop: "3px", marginLeft: "8px", color: "#CCC" }}
                            >
                                <span className="align-text-top">{uploadMessage}</span>
                            </div>
                        </Col>

                    </Row>
                </Form>
            </Container>
            <Container className={`lambent-casting-container crossfade-wrapper ${hasSubmitted ? "" : "cf-hidden"}`}>
                <p>Thank you for submitting your responses. If you have any questions, please get in touch with a member of our team at <a href="mailto:casting@lambent.tv">casting@lambent.tv</a></p>
            </Container>
        </>
    )
}

export const UploadFileWithRelease = (props) => {
    const [cName, setCName] = useState("")
    const [cId, setCId] = useState("")
    const [cDescription, setCDescription] = useState("")
    const [cAge, setCAge] = useState("")
    const [cEmail, setCEmail] = useState("")
    const [cPhone, setCPhone] = useState("")
    const [cConditions, setCConditions] = useState([])
    const [agreedToContributorRelease, setAgreedToContributorRelease] = useState(false)

    const [isSaving, setIsSaving] = useState(false)
    const [uploadMessage, setUploadMessage] = useState(null)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const [cSelectedFiles, setCSelectedFiles] = useState([])

    const [nameRequired, setNameRequired] = useState(true)
    const [idRequired, setIdRequired] = useState(true)
    const [alreadySetId, setAlreadySetId] = useState(false)

    const [hasChanges, setHasChanges] = useState(false)
    const [validated, setValidated] = useState(false)
    const [videoIsValid, setVideoIsValid] = useState(true)
    const [showIdOrNameValidation, setShowIdOrNameValidation] = useState(false)

    const [ignoreId, setIgnoreId] = useState(false)

    // Notify parents that changes have occurred
    useEffect(() => {
        props.onUpdate(hasChanges)
        if (props.contribId && cId.length === 0 && !alreadySetId) {
            setCId(props.contribId)
            setAlreadySetId(true)
        }
    }, [hasChanges, props, cId, alreadySetId])

    // Make this component manageable
    useEffect(() => {
        if (typeof props.cName !== 'undefined' && (props.cName.length !== cName.length || !props.cName.includes(cName))) {
            setCName(props.cName)
        }

        if (typeof props.cId !== 'undefined' && (props.cId.length !== cId.length || !props.cId.includes(cId))) {
            setCId(props.cId)
        }
    }, [props, cId, cName])

    // Check for changes
    useEffect(() => {
        if (cName && cName.length > 0) {
            setHasChanges(true)
        } else if (cId && cId.length > 0) {
            setHasChanges(true)
        } else if (cSelectedFiles && cSelectedFiles.length > 0) {
            setHasChanges(true)
        } else {
            setHasChanges(false)
        }

        var hasName = false
        var hasId = false

        if (cName && cName.length > 0) {
            hasName = true
        }

        if (cId && cId.length > 0) {
            hasId = true
        }

        setNameRequired(!hasId)
        setIdRequired(!hasName)

        setShowIdOrNameValidation(validated && !hasId && !hasName)

    }, [cName, cId, cSelectedFiles, validated])

    const fileSelected = (e) => {
        if (e.target.files?.length > 0) {
            var fileIsTooBig = false

            for (var i = 0; i < e.target.files.length; i++) {
                console.log("File size: " + e.target.files[i].size)
                if (e.target.files[i].size > 1024 ** 3 * 5) {
                    fileIsTooBig = true
                }
            }

            if (fileIsTooBig) {
                console.log("too big")
                setVideoIsValid(false)
                e.target.setCustomValidity('too-big')
            } else {
                console.log("just right")
                setVideoIsValid(true)
                e.target.setCustomValidity('')
            }

            setCSelectedFiles(e.target.files)
        } else {
            setCSelectedFiles(null)
        }
    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);

        var contribToSubmit = {
            agreedToContributorRelease: agreedToContributorRelease,
            email: cEmail
        }

        if (cName && cName.length > 1)
            contribToSubmit.fullName = cName

        if (cPhone && cPhone.length > 1)
            contribToSubmit.phone = cPhone

        if (form.checkValidity() === true) {
            if (cSelectedFiles) {
                let submitId = cId && cId.length > 3 ? cId : null
                setIsSaving(true)
                api.uploadVideos(cSelectedFiles, contribToSubmit, ignoreId ? null : submitId, props.questionType, (message) => {
                    console.log("Message: " + message)
                    setUploadMessage(message)
                })
                    .then((res) => {
                        let contribId = res.contribId
                        let videosToAdd = []

                        setUploadMessage('Sending your information (final step!)')
                        console.log("Upload result: " + JSON.stringify(res))

                        res.keys.forEach((key) => {
                            let newKey = key.substring(key.lastIndexOf('/' + 1))
                            console.log("New key: " + newKey)

                            var newDescription = cDescription ? cDescription : props.questionType ? "Answers for questions relating to " + props.questionType : "No description given"

                            newDescription = newDescription + " || ACCEPTED RELEASE: " + agreedToContributorRelease

                            let newVideo = {
                                title: newKey,
                                description: newDescription
                            }
                            videosToAdd.push(newVideo)
                        })

                        console.log('Submitting videos: ' + JSON.stringify(videosToAdd))

                        api.addVideos(contribId, videosToAdd)
                            .then((res) => {
                                console.log("We did it! " + JSON.stringify(res))
                                setUploadMessage('Upload complete!')
                                setIsSaving(false)
                                setHasSubmitted(true)
                                props.finishedUpload(res, null)
                            })
                            .catch((err) => {
                                setIsSaving(false)
                                setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                                alert('An error occurred submitting your information, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
                                props.finishedUpload(null, err)
                            })
                    })
                    .catch((err) => {
                        setIsSaving(false)
                        if (!ignoreId && cName?.length > 1) {
                            // We haven't tried uploading without the ID so let's do that
                            setIgnoreId(true)
                            handleSubmit(e)
                        } else {
                            if (cId && cId.length > 3 && !ignoreId && (!cName || cName?.length < 2)) {
                                setUploadMessage('Unable to submit using ID. Please try entering your full name and retry.')
                                props.finishedUpload(null, err)
                            } else {
                                setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                                alert("Something went wrong here: " + JSON.stringify(err))
                                props.finishedUpload(null, err)
                            }
                        }
                    })
            }
        }
    }

    return (
        <>
            <Container className={`lambent-casting-container crossfade-wrapper${!agreedToContributorRelease ? " cf-hidden" : hasSubmitted ? " cf-hidden" : ""}`}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="lambent-casting-form">
                    <Form.Label as={Row}>
                        <p style={{ color: '#AAA' }}><span className="form-red-star">* </span>Indicates required field</p>
                        <br /><br />
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Name<span className="form-red-star">*</span>
                        </Form.Label>
                        <Col sm={8}>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    minLength={2}
                                    type="text"
                                    value={cName}
                                    onChange={(e) => setCName(e.target.value)}
                                    disabled={isSaving}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your full name
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Age<span className="form-red-star">*</span>
                        </Form.Label>
                        <Col sm={8}>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    min={18}
                                    type="number"
                                    value={cAge}
                                    onChange={(e) => setCAge(e.target.value)}
                                    disabled={isSaving}
                                />
                                <Form.Control.Feedback type="invalid">
                                    You must be over 18 in order to take part
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Email Address<span className="form-red-star">*</span>
                        </Form.Label>
                        <Col sm={8}>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    type="email"
                                    value={cEmail}
                                    onChange={(e) => setCEmail(e.target.value)}
                                    disabled={isSaving}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Phone Number
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="phone"
                                value={cPhone}
                                onChange={(e) => setCPhone(e.target.value)}
                                disabled={isSaving}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Video(s)<span className="form-red-star">*</span>
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="file"
                                required
                                onChange={fileSelected}
                                capture={false}
                                multiple
                                accept="video/*,video/mp4,video/x-m4v"
                                disabled={isSaving}
                                isValid={videoIsValid}
                            />
                            <Form.Text className="form-red-star" hidden={videoIsValid}>
                                Video file must be less than 5GB in size
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col md={4} sm={0} />
                        <Col md={8} sm={12}>
                            <Button
                                type="submit"
                                className="float-start"
                                disabled={isSaving}>
                                {
                                    !isSaving ? "Submit" : "Submit"
                                }
                            </Button>
                            <Spinner
                                className="float-start align-middle"
                                hidden={!isSaving}
                                animation="border"
                                variant="secondary"
                                style={{ marginTop: "3px", marginLeft: "10px" }}
                            />
                            <div
                                className="float-start"
                                hidden={!uploadMessage}
                                style={{ marginTop: "3px", marginLeft: "8px", color: "#CCC" }}
                            >
                                <span className="align-text-top">{uploadMessage}</span>
                            </div>
                        </Col>

                    </Row>
                </Form>
            </Container>
            <Container className={`lambent-casting-container crossfade-wrapper${agreedToContributorRelease ? " cf-hidden" : ""}`}>
                <TermsAndConditions
                    clickedAgree={() => setAgreedToContributorRelease(true)}
                />
            </Container>
            <Container className={`lambent-casting-container crossfade-wrapper ${hasSubmitted ? "" : "cf-hidden"}`}>
                <p>Thank you for submitting your responses. If you have any questions, please get in touch with a member of our team at <a href="mailto:casting@lambent.tv">casting@lambent.tv</a></p>
            </Container>
        </>
    )
}

const TermsAndConditions = (props) => {
    const [tickedAgree, setTickedAgree] = useState(false)

    return (
        <>
            <Row>
                <Col>
                    <h4>TERMS AND CONDITIONS</h4>
                    <p>Please read the following and click 'I Agree' before submitting your video</p>

                </Col>
            </Row>
            <Row className="terms-and-conditions">
                <Col>
                    <p>For the purposes of this agreement the "Programme" is the production currently titled "Body Secrets: Women's Health Uncovered" (working title), including all re-versions, cut-downs, re-edits and compilations thereof.</p>
                    <p>In this series we explore the biggest health issues for women, from severe period pains to incontinence – conditions that are often kept hidden. Breaking the taboos and overcoming the stigma, this will be a hands-on and enlightening guide about living with common conditions that many women struggle to speak about.</p>
                    <p>The series will uncover the secrets and taboos surrounding women’s health.  Unashamed, unembarrassed, unflinching, this is a series that tackles women’s health with humour, warmth and clear-sighted questioning that demands answers.</p>
                    <p>By uploading this video you:</p>
                    <ul>
                        <li>Irrevocably agree to grant to us the right to use, edit, reproduce and/or exploit, as we in our sole discretion think, any photographic/video material uploaded by you (“Video Submission”) in connection with the development, casting, production, distribution, promotion and/or exploitation of the Programme and/or ancillary products/services derived therefrom to enable us to make the fullest use of the Video Submission in connection with the Programme worldwide in perpetuity in any and all media now known or hereafter developed, without any liability or further fees becoming due to you or any third party;</li>
                        <li>acknowledge and agree the nature and content of the Programme and you agree to participate in the Programme (which we intend, but do not undertake, to produce) by way of the Video Submission;</li>
                        agree that we have no obligation to return any Video Submission to you
                        grant the rights hereunder whether or not you are selected as a participant in the Programme; and
                        <li>agree that we shall not be liable to you for any loss, damage whatsoever or injury caused or suffered in connection with the Video Submission and/or Programme, except for death or personal injury caused by the Producer’s negligence and recoverable on that ground.  In the event of any breach of this agreement your remedies shall be an action at law for damages, if any actually suffered and you shall not have the right to rescind this agreement or terminate the rights granted in this agreement or to enjoin restrain or prevent the production distribution exhibition broadcast advertising or exploitation of the Programme.</li>
                    </ul>
                    <p>Further, by uploading the video, you explicitly consent to the processing of any special category of personal information that you may have provided in your Video Submission. You understand that such data will be processed in accordance with our <a href="https://lambent.tv/privacy" target="_blank" rel="noreferrer">privacy notice</a>.</p>
                </Col>
            </Row>
            <Row style={{ height: "1em" }}></Row>
            <Row>
                <Col>
                    <Button className="float-end" onClick={props.clickedAgree}>I Agree</Button>
                </Col>
            </Row>
        </>
    )
}

// ------------------------
// --- Helper functions ---
// ------------------------
export function conditionValueToLabel(condition, isBackend) {
    if (isBackend)
        return conditions.find(x => x.value === condition).backend_label || "NOT FOUND"
    else
        return conditions.find(x => x.value === condition).contrib_label || "NOT FOUND"
}