import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { UploadModal } from '../../formElements';

import { FilmingTipsModal } from '../FilmingTips';

import questions from './questions.json'

const ContributorQuestions = (props) => {
    const { hash, pathname } = useLocation()
    const navigate = useNavigate()

    const [selectedCondition, setSelectedCondition] = useState(null)
    const [showTips, setShowTips] = useState(false)
    const [showUpload, setShowUpload] = useState(false)

    const [currentId, setCurrentId] = useState(null)

    useEffect(() => {
        setShowTips(hash.includes('tips'))
        if (hash.includes('print')) {
            printQuestions()
        }

        if (hash.includes('upload')) {
            setShowUpload(true)
        } else {
            setShowUpload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash])

    useEffect(() => {
        // remove preceding slash if it exists, so we don't get unexpected URLs
        let newPath = pathname.replace(/^\/+/, '')

        let parts = newPath.split('/')
        setSelectedCondition(parts[1])
        setCurrentId(parts[2])
    }, [pathname])

    function printQuestions() {
        // handlePrint()
        window.print()

        navigate(pathname, { replace: true })
    }

    return (
        <>
            <Container className="lambent-casting-container">
                <div style={{ height: "40px" }} className='no-print' />
                <Row className='no-print'>
                    <h5>TAKE PART</h5>
                    <h1>BODY SECRETS</h1>
                    <p>Thank you for your interest in sending us a video for <b>Body Secrets: Women's Health Uncovered</b> (working title).</p>
                    
                    <p>Before you get started, please take a look at our <Link to="#tips" replace={true}>quick tips for filming</Link>, to make sure your video is the best quality it can be.</p>
                </Row>
                <Row>
                    <Questions
                        condition={selectedCondition}
                    />
                </Row>
            </Container>
            <FilmingTipsModal
                className='no-print'
                shouldShow={showTips}
                onHide={() => navigate(pathname, { replace: true })}
                />
            <UploadModal
                className='no-print'
                contribId={currentId}
                shouldShow={showUpload}
                onHide={() => navigate(pathname, { replace: true })}
                questionType={selectedCondition}
                showRelease
            />
        </>
    )
}

const Questions = props => {
    let condition = questions[props.condition]

    if (!condition) {
        return (
            <>
                <hr />
                <p>We are looking for people to answer questions on the following topics:</p>
                <div>
                    <ul>
                        {
                            Object.keys(questions).map((key) =>
                                <li key={key}><Link to={key}>{questions[key].title}</Link></li>
                            )
                        }
                    </ul>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Row className='no-print'>
                    <p>Answer as many of the questions below as you feel comfortable doing. You may want to record each question as a separate video clip, to make it easier (you'll be able to upload them all at once). If it helps, you can also <Link to="#print" replace={true}>print the questions out</Link>.</p>
                    <p>When answering a question, try to include the question in the answer. For example, for the question <b>"What colour is the sky today?"</b> instead of just saying <b>"Blue"</b> you would answer <b>"The sky is blue today"</b>.</p>
                    <p>Once you've finished recording, you can <Link to="#upload" replace={true}>upload your videos</Link>.</p>
                    <hr />
                </Row>
                <QuestionList condition={condition} />
                <Row className="no-print">
                    <hr />
                    <Col sm={4}>
                        <Link to="#tips" replace={true}><Button style={{ width: "100%" }}>Show Tips</Button></Link>
                    </Col>
                    <Col sm={4}>
                        <Link to="#print" replace={true}><Button style={{ width: "100%" }}>Print Questions</Button></Link>
                    </Col>
                    <Col sm={4}>
                        <Link to="#upload" replace={true}><Button style={{ width: "100%" }}>Upload Clips</Button></Link>
                    </Col>
                    <hr style={{ marginTop: "15px" }} />
                </Row>
            </>
        )
    }
}

const QuestionList = (props) => {
    return (
        <Row id='Questions'>
            <Col>
                <h4>{props.condition?.title.toUpperCase()} - QUESTIONS</h4>
                <ol>
                    {props.condition?.questions.map((question, i) => <li key={'question-' + i} className="bs-question">{question}</li>)}
                </ol>
            </Col>
        </Row>
    )
}

export default ContributorQuestions