import React, { createContext, useState, useEffect } from 'react'

import { useMsal } from '@azure/msal-react'
import axios from 'axios'

const base = "https://api.casting.lambent.tv"

export function useAdminAuth() {
    const [_graphToken, setGraphToken] = useState(null)
    const [_apiToken, setApiToken] = useState(null)
    const [adminApi, setAdminApi] = useState(null)

    const { instance, accounts, inProgress } = useMsal()

    instance.setActiveAccount(accounts[0])

    function updateGraphToken() {
        instance.acquireTokenSilent({
            scopes: ["User.Read"]
        })
            .then(token => {
                setGraphToken(token)
                console.log("Set Graph Token")
            })
            .catch(err => console.log("Graph Error: " + err.message))
    }

    function updateApiToken() {
        instance.acquireTokenSilent({
            scopes: ["https://api.casting.lambent.tv/BS-Contributors.Update.All", "https://api.casting.lambent.tv/BS-Contributors.Read.All", "https://api.casting.lambent.tv/BS-Permissions.Check.All", "https://api.casting.lambent.tv/BS-Permissions.Update.All"]
        })
            .then(token => {
                setApiToken(token)
                console.log("Set API Token")
            })
            .catch(err => console.log("API Error: " + JSON.stringify(err)))
    }

    if (!_graphToken) {
        // Get a new graph token
        updateGraphToken()
    }

    if (!_apiToken) {
        updateApiToken()
    }

    // Set up/update the API object, only when both tokens are ready
    useEffect(() => {
        if (!adminApi) {
            if (_graphToken && _apiToken) {
                console.log("Making new admin API")
                setAdminApi(new BodySecretsAPI(_graphToken, _apiToken))
            }
        } else {
            if (adminApi.apiToken !== _apiToken)
                adminApi.apiToken = _apiToken

            if (adminApi.graphToken !== _graphToken)
                adminApi.graphToken = _graphToken
        }
    }, [_apiToken, _graphToken, adminApi])

    const graphToken = (() => {
        if (_graphToken && tokenWillExpire(_graphToken, 120)) {
            updateGraphToken()
        }

        return _graphToken
    })()

    const apiToken = (async () => {
        return _apiToken
    })()

    return { graphToken, apiToken, adminApi }
}

// Returns whether or not a token will expire in the next x seconds
function tokenWillExpire(token, seconds) {
    if (!token || !seconds) {
        return false
    }

    return timeUntilTokenExpires(token) < seconds
}

function timeUntilTokenExpires(token) {
    if (!token) {
        return null
    }

    let expiryDate = new Date(token.expiresOn)
    let now = new Date()

    let secondsUntil = Math.floor((expiryDate.getTime() - now.getTime()) / 1000)

    return secondsUntil
}

export class BodySecretsAPI {
    _apiToken = null
    _graphToken = null

    checkTokensNeedUpdate = null

    get apiToken() {
        return this._apiToken
    }

    get graphToken() {
        return this._graphToken
    }

    set apiToken(newValue) {
        this._apiToken = newValue
        this.updateTokens()
    }

    set graphToken(newValue) {
        this._graphToken = newValue
    }

    get apiJwt() {
        if (!this.apiToken) {
            return null
        } else {
            return 'Bearer ' + this.apiToken.accessToken
        }
    }

    get graphJwt() {
        if (!this.graphToken) {
            return null
        } else {
            return 'Bearer ' + this.graphToken.accessToken
        }
    }

    updateTokens() {
        if (this.apiJwt)
            axios.defaults.headers.common['Authorization'] = this.apiJwt
    }

    constructor(graphToken, apiToken) {
        if (graphToken) {
            this.graphToken = graphToken
        } else {
            this.graphToken = null
        }

        if (apiToken) {
            this.apiToken = apiToken
        } else {
            this.apiToken = null
        }
    }

    // TODO: Get the current status of the API
    getStatus() {
        return new Promise((resolve, reject) => {
            let url = base + '/'

            axios.get(url)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    checkIsAdmin() {
        return new Promise((resolve, reject) => {
            let url = base + '/checkadmin'
            let config = {
                headers: {
                    Authorization: this.apiJwt
                }
            }

            axios.get(url, config)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    // Get all contributors
    getContributors() {
        return new Promise((resolve, reject) => {
            let url = base + '/contributors'

            axios.get(url)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    // Post new contributor
    newContributor(contrib) {
        return new Promise((resolve, reject) => {
            let url = base + '/contributors/new'

            console.log("Making call to New Contributor with contrib: " + JSON.stringify(contrib))

            axios.post(url, contrib)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    // Update a contributor
    updateContributor(contrib) {
        return new Promise((resolve, reject) => {
            let url = base + '/contributors/' + contrib.id

            console.log("Making call to update new contrib with URL: " + url)

            axios.post(url, contrib)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    // Add videos to a contributor
    addVideos(id, videos) {
        return new Promise((resolve, reject) => {
            let url = base + '/contributors/' + id + '/addvideos'

            console.log("Making call to add videos to contrib with URL: " + url)
            console.log("And videos: " + JSON.stringify(videos))

            axios.post(url, {videos: videos})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    console.log("Could not add videos: " + JSON.stringify(err))
                    reject(err)
                })
        })
    }

    // Upload videos: accepts an array of one or more videos
    uploadVideos(videos, contributor, id, questionType, progressCallback) {
        return new Promise(async (resolve, reject) => {
            if (videos?.length === 0) {
                reject(new Error('You must provide at least one video'))
            }

            if (!contributor && !id) {
                reject(new Error('You must provide either a contributor or an ID'))
            }

            var vidStr = videos.length > 1 ? 'videos' : 'video'

            if (progressCallback)
                progressCallback('Preparing to upload ' + vidStr + '…', 0)

            // TODO: Make things work so that the videos can have different extensions
            let extension = videos[0].name.split('.').pop()

            this.generateUploadUrl(extension, contributor, id, videos.length, questionType)
                .then((res) => {
                    console.log("Got video URL: " + JSON.stringify(res))

                    var previousProgress = 0
                    var totalProgress = {}

                    var totalToLoad = 0

                    const updateProgress = (progressId, loaded) => {
                        totalProgress[progressId] = loaded

                        var currentLoaded = 0

                        Object.keys(totalProgress).forEach(key => currentLoaded += totalProgress[key])

                        let percentDone = Math.round((currentLoaded * 100) / totalToLoad)

                        if (previousProgress !== percentDone) {
                            previousProgress = percentDone
                            if (progressCallback)
                                progressCallback(`Uploading video file${videos.length > 1 ? 's' : ''}: ${percentDone}%`,
                                    percentDone
                                )
                        }
                    }

                    function makeProgressCallback(progressId) {
                        return loaded => updateProgress(progressId, loaded)
                    }

                    var promises = []

                    let contribId = res.data.contribId
                    let urls = res.data.videoUrls
                    let keys = Object.keys(urls)

                    for (var i = 0; i < videos.length; i++) {
                        let progressId = 'update-' + i

                        totalToLoad += videos[i].size
                        totalProgress[progressId] = 0

                        promises.push(
                            this.uploadFile(videos[i], urls[keys[i]], makeProgressCallback(progressId))
                        )
                    }

                    Promise.all(promises)
                        .then((res) => {
                            console.log("Responses: " + JSON.stringify(res))
                            resolve({ contribId: contribId, keys: keys })
                        })
                        .catch((err) => {
                            console.log("Failed at upload")
                            reject(new Error("Failed at upload: " + err.message))
                        })
                })
                .catch((err) => {
                    console.log("Failed at URL generation")
                    reject(new Error("Failed at URL generation: " + err.message))
                })
        })
    }

    // Get presigned URL
    generateUploadUrl(extension, contributor, id, numberOfUrls, questionType) {
        return new Promise((resolve, reject) => {
            if (!id && !contributor) {
                reject(new Error("You must supply either the contributor's full name, or an ID if they already exist"))
                return
            }

            let url = base + '/videourl/'

            let bodyObject = { 'extension': extension }

            if (id) {
                url = url + id
            } else {
                // Only set the full name if we don't have an ID, else it will override the 'incomplete' parameter
                bodyObject['fullName'] = contributor.fullName
                bodyObject['contributor'] = contributor
            }

            if (numberOfUrls) {
                bodyObject['numberOfUrls'] = numberOfUrls
            }

            if (questionType) {
                bodyObject['questionType'] = questionType
            }

            console.log("Making call here : " + url)

            axios.post(url, bodyObject)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    // Upload file <5GB
    uploadFile(file, url, progressCallback) {
        return new Promise((resolve, reject) => {
            axios.put(url, file, {
                onUploadProgress: (progressEvent) => {
                    progressCallback(progressEvent.loaded, progressEvent.total)
                }
            })
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    // TODO: Make multipart uploads work
    // uploadMultipartFile(file, urls, progressCallback) {
    //     api.generateUploadId(extension, cName, null)
    //         .then((res) => {
    //             console.log(JSON.stringify(res))
    //             let key = res.data.key
    //             let uploadId = res.data.uploadId

    //             api.generatePresignedUrls(uploadId, parts, key)
    //                 .then((res) => {
    //                     setUploadMessage('Uploading video file: 1%')
    //                     console.log(JSON.stringify(res))

    //                     api.uploadLargeFile(uploadId, key, res.data.urls, cSelectedFile, (percentDone) => {
    //                         console.log(percentDone)
    //                         setUploadMessage('Uploading video file: ' + percentDone.toString() + '%')
    //                     })
    //                         .then((finalParts) => {
    //                             setUploadMessage('Finishing video upload')

    //                             api.completeUpload(uploadId, finalParts, key)
    //                                 .then((res) => {
    //                                     setUploadMessage('Uploading your information (final step!)')

    //                                     contribToSubmit.id = key.substring(0, key.lastIndexOf('/'))
    //                                     contribToSubmit.videos = [key.substring(key.lastIndexOf('/' + 1))]
    //                                     console.log("Supposedly combined item: " + JSON.stringify(res))

    //                                     console.log('Submitting contrib: ' + JSON.stringify(contribToSubmit))

    //                                     api.updateContributor(contribToSubmit)
    //                                         .then((res) => {
    //                                             console.log("We fucking did it! " + JSON.stringify(res))
    //                                             setUploadMessage('Upload complete!')
    //                                             setIsSaving(false)
    //                                             setHasSubmitted(true)
    //                                         })
    //                                         .catch((err) => {
    //                                             setIsSaving(false)
    //                                             setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
    //                                             alert('An error occurred submitting your information, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
    //                                         })
    //                                 })
    //                                 .catch((err) => {
    //                                     setIsSaving(false)
    //                                     setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
    //                                     alert('An error occurred completing the file upload, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
    //                                 })
    //                         })
    //                         .catch((err) => {
    //                             setIsSaving(false)
    //                             setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
    //                             alert('An error occurred uploading the final file, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
    //                         })
    //                 })
    //                 .catch((err) => {
    //                     setIsSaving(false)
    //                     setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
    //                     alert('An error occurred generating upload URLs, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
    //                 })
    //         })
    //         .catch((err) => {
    //             setIsSaving(false)
    //             setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv if problem persists')
    //             alert('An error occurred generating upload ID, please screnshot and email chloe@lambent.tv\n\n' + JSON.stringify(err))
    //         })
    // }

    // TODO: Generate an upload ID for the current contributor

    generateUploadId(extension, fullName, id) {
        return new Promise((resolve, reject) => {
            if (!id && !fullName) {
                reject(new Error("You must supply either the contributor's full name, or an ID if they already exist"))
            }

            let url = base + '/uploadid/'

            if (id) {
                url = url + '/' + id
            }

            let bodyObject = { 'extension': extension }

            if (fullName) {
                bodyObject['fullName'] = fullName
            }

            console.log("Making call here : " + url)

            axios.post(url, bodyObject)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    // TODO: Generate the URLs needed to upload
    generatePresignedUrls(uploadId, parts, key) {
        return new Promise((resolve, reject) => {
            if (!uploadId || !parts || !key) {
                reject(new Error('Missing parameters: \n-uploadId: ' + uploadId + '\n- parts: ' + parts + '\n-key: ' + key))
            }

            let url = base + '/presingedurls'

            console.log("Making call : " + url)

            axios.post(url, {
                'uploadId': uploadId,
                'parts': parts,
                'key': key
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    uploadLargeFile(uploadId, key, urls, file, updateHandler) {
        return new Promise((resolve, reject) => {
            var chunks = [];
            const chunkSize = 5000000
            var i = 0

            while (i < file.size) {
                chunks.push(file.slice(i, i + chunkSize))

                i = i + chunkSize

                console.log('Chunking: ' + i)
            }

            const axiosCall = axios.create()
            delete axiosCall.defaults.headers.put['Content-Type']

            var promises = []

            const progresses = []
            let previousProgress = 0
            const progressUpdate = (index, percent) => {
                progresses[index] = percent

                var totalProgress = 0
                progresses.forEach(progress => {
                    totalProgress += progress
                })

                let newProgress = Math.round(totalProgress / progresses.length)

                if (newProgress !== previousProgress) {
                    previousProgress = newProgress
                    updateHandler(newProgress)
                }
            }

            urls.forEach((element, index) => {
                progresses[index] = 0
                console.log("Working on url " + index + ' of ' + urls.length)
                promises.push(
                    axiosCall.put(element, chunks[index], {
                        onUploadProgress: (progressEvent) => {
                            progressUpdate(index, Math.round((progressEvent.loaded * 100) / progressEvent.total))
                        }
                    })
                )
            })

            Promise.all(promises)
                .then((res) => {
                    var retVals = []

                    res.forEach((item, index) => {
                        retVals.push({
                            ETag: item.headers.etag,
                            PartNumber: index + 1
                        })
                        resolve(retVals)
                    })
                })
                .catch(err => reject(err))

        })
    }

    // TODO: Tell the server you have finished uploading and it needs to complete the file
    completeUpload(uploadId, finalParts, key) {
        return new Promise((resolve, reject) => {
            if (!uploadId || !finalParts || !key) {
                reject(new Error('Missing parameters: \n-uploadId: ' + uploadId + '\n- finalParts: ' + finalParts + '\n-key: ' + key))
            }

            let url = base + '/completeupload'

            console.log("Making call : " + url)

            axios.post(url, {
                'uploadId': uploadId,
                'finalParts': finalParts,
                'key': key
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
}