import React from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useIsAuthenticated } from '@azure/msal-react'
import { LPNavBarUser } from './msal-components'

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated()

    return (
        <>
            <Navbar variant="dark" bg="dark" expand="md">
                <Container fluid>
                    <Navbar.Brand href="/admin"><b>Body Secrets Casting</b> – Admin</Navbar.Brand>
                    <LPNavBarUser />
                </Container>
            </Navbar>
            <Container fluid>
                {props.children}
            </Container>
        </>
    )
}

