import React, { useEffect, useState } from 'react'

import { useMsal } from '@azure/msal-react'
import { useIsAuthenticated } from '@azure/msal-react'

import { loginRequest } from './authConfig'

import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

function handleLogout(instance) {
    instance.logoutPopup().catch(e => {
        console.log(e)
    })
}

function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch(e => {
        console.error(e)
    })
}

export const SignInButton = () => {
    const { instance } = useMsal()

    return (
        <Button
            variant="primary"
            className="ml-auto"
            onClick={() => handleLogin(instance)
            }>
            Sign In
        </Button>
    )
}

export const SignOutButton = () => {
    const { instance } = useMsal()

    return (
        <Button
            variant="secondary"
            className="ml-auto"
            onClick={() => handleLogout(instance)}>
            Sign Out
        </Button>
    )
}

export const LPNavBarUser = (props) => {
    const { instance, accounts } = useMsal()
    const isAuthenticated = useIsAuthenticated()

    const userName = accounts[0] && accounts[0].name
    const userEmail = accounts[0] && accounts[0].username

    if (isAuthenticated) {
        return (
            <Nav className="ml-auto">
                <NavDropdown id="userDropdown" menuVariant="dark" align="end" title={userName ? userName : "ERROR"}>
                    <NavDropdown.Header>{userEmail ? userEmail : "ERROR"}</NavDropdown.Header>
                    {/* <NavDropdown.Divider/> */}
                    <NavDropdown.Item onClick={() => handleLogout(instance)}>Log Out</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        )
    } else {
        return (
            <Nav className="ml-auto">
                <Nav.Item>
                    <SignInButton />
                </Nav.Item>
            </Nav>
        )
    }
}

const AccessTokenStuff = (props) => {
    const { instance, accounts } = useMsal()
    const isAuthenticated = useIsAuthenticated()

    const [accessToken, setAccessToken] = useState(null)

    useEffect(() => {
        if (isAuthenticated) {
            const req = {
                ...loginRequest,
                account: accounts[0]
            }
    
            instance.acquireTokenSilent(req).then(res => {
                setAccessToken(res.accessToken)
            }).catch(e => {
                instance.acquireTokenPopup(req).then(res => {
                    setAccessToken(res.accessToken)
                })
            })

            console.log("Did this: ")
        }
    }, [isAuthenticated, accounts, instance])
}