import React, { useState, useEffect } from 'react'

import { EditableTickList } from '../formElements'
import { FilmingTipsModal } from './FilmingTips'

import { Container, Row, Col, Button, Spinner, Form, InputGroup } from 'react-bootstrap'

import { BodySecretsAPI } from '../bs-api'

import { useLocation, useNavigate } from 'react-router-dom'
// import reactSelect from 'react-select'

const api = new BodySecretsAPI()

const BodySecretsMainContribForm = (props) => {
    return (
        <Container className="lambent-casting-container">
            <Row style={{ height: '40px' }} />
            <BodySecretsHeader />
            <hr />
            <BodySecretsForm />
            <Row style={{ height: '40px' }} />
        </Container>
    )
}

export const BodySecretsHeader = (props) => {
    return (
        <Row>
            <h5>TAKE PART</h5>
            <h1>BODY SECRETS</h1>
            <p>Award-winning TV production company Lambent are making a <b>new TV Series for Channel 5</b>, exploring women’s health. The series will delve into some of the biggest health issues women regularly experience.</p>
            <p>We're looking for women who are happy to feature in the series and <b>talk openly about their conditions</b>. Whether you have previously been treated and want to share a past experience of living with these conditions, are currently undergoing treatment and would be happy to share your journey, or are currently suffering and would like to put a product or treatment to the test and give your verdict, we want to hear from you!</p>
            <p>If any of this sounds like you, <b>please use the form below to get in touch!</b></p>
        </Row>
    )
}

export const BodySecretsForm = (props) => {
    // const allowLargerThan5GB = false

    const { hash } = useLocation()
    const navigate = useNavigate()

    const [cName, setCName] = useState("")
    const [cLocation, setCLocation] = useState("")
    const [cAge, setCAge] = useState("")
    const [cEmail, setCEmail] = useState("")
    const [cPhone, setCPhone] = useState("")
    const [cConditions, setCConditions] = useState([])
    const [cMessage, setCMessage] = useState("")
    const [cSelectedFiles, setCSelectedFiles] = useState("")
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false)

    const [isSaving, setIsSaving] = useState(false)
    const [uploadMessage, setUploadMessage] = useState(null)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const [validated, setValidated] = useState(false)
    // const [videoIsValid, setVideoIsValid] = useState(true)

    const [showTips, setShowTips] = useState(false)

    useEffect(() => {
        setShowTips(hash.includes('tips'))
    }, [hash])

    const handleSubmit = (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);

        var contribToSubmit = {
            "fullName": cName,
            "location": cLocation,
            "age": cAge,
            "email": cEmail,
            "phone": cPhone,
            "message": cMessage,
            "conditions": cConditions,
            "agreedToPrivacy": agreedToPrivacy
        }

        if (form.checkValidity() === true) {
            if (cSelectedFiles) {
                setIsSaving(true)
                api.uploadVideos(cSelectedFiles, contribToSubmit, null, null, (message) => {
                    setUploadMessage(message)
                })
                    .then((res) => {
                        let contribId = res.contribId

                        setUploadMessage('Sending your information (final step!)')
                        console.log("Upload result: " + JSON.stringify(res))

                        var videosToAdd = []

                        res.keys.forEach((key) => {
                            let newKey = key.substring(key.lastIndexOf('/' + 1))
                            console.log("New key: " + newKey)

                            let newVideo = {
                                title: newKey,
                                description: "Video from initial form"
                            }
                            videosToAdd.push(newVideo)
                        })

                        console.log('Submitting contrib videos: ' + JSON.stringify(videosToAdd))

                        api.addVideos(contribId, videosToAdd)
                            .then((res) => {
                                console.log("We did it! " + JSON.stringify(res))
                                setUploadMessage('Upload complete!')
                                setIsSaving(false)
                                setHasSubmitted(true)
                            })
                            .catch((err) => {
                                setIsSaving(false)
                                setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                                alert('An error occurred submitting your information, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
                            })
                    })
                    .catch((err) => {
                        setIsSaving(false)
                        setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                        alert("Another error occurred: " + err.message)
                    })
                // TODO: MAKE THE CODE FOR LARGE FILES WORK
            } else {
                setIsSaving(true)
                setUploadMessage('Submitting your information')
                api.newContributor(contribToSubmit)
                    .then((res) => {
                        setIsSaving(false)
                        setHasSubmitted(true)
                        setUploadMessage('Submitted succesfully!')
                    })
                    .catch((err) => {
                        setIsSaving(false)
                        setUploadMessage('Submission failed. Please try pressing submit again or email chloe@lambent.tv')
                        alert('An error occurred generating upload ID, please screnshot and email chloe@lambent.tv if problem persists\n\n' + JSON.stringify(err))
                    })
            }
        }
    }

    // const fileSelected = (e) => {
    //     if (e.target.files?.length > 0) {
    //         var fileIsTooBig = false

    //         for (var i = 0; i < e.target.files.length; i++) {
    //             console.log("File size: " + e.target.files[i].size)
    //             if (e.target.files[i].size > 1024 ** 3 * 5) {
    //                 fileIsTooBig = true
    //             }
    //         }

    //         if (fileIsTooBig) {
    //             console.log("too big")
    //             setVideoIsValid(false)
    //             e.target.setCustomValidity('too-big')
    //         } else {
    //             console.log("just right")
    //             setVideoIsValid(true)
    //             e.target.setCustomValidity('')
    //         }

    //         setCSelectedFiles(e.target.files)
    //     } else {
    //         setCSelectedFiles(null)
    //     }
    // }

    if (hasSubmitted) {
        return (
            <Row>
                <Col>
                    <h1>THANK YOU</h1>
                    <p>Thank you for sending in your details. We will endeavour to speak to as many people as possible about the project, however we may not be able to speak to everyone. If we are interested in potentially featuring your story as part of the series a member of the team will be in touch before the end of February 2022.</p>
                    <p>If you have any questions in the meantime, please email <a href="mailto:casting@lambent.tv">casting@lambent.tv</a>.</p>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="lambent-casting-form">
                <Form.Label as={Row}>
                    <p style={{ color: '#AAA' }}><span className="form-red-star">* </span>Indicates required field</p>
                    <br /><br />
                </Form.Label>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Name<span className="form-red-star">*</span>
                    </Form.Label>
                    <Col sm={8}>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                minLength={2}
                                type="text"
                                value={cName}
                                onChange={(e) => setCName(e.target.value)}
                                disabled={isSaving}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your full name
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>

                    <Form.Label column sm={4}>
                        Age<span className="form-red-star">*</span>
                    </Form.Label>
                    <Col sm={8}>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                min={18}
                                type="number"
                                value={cAge}
                                onChange={(e) => setCAge(e.target.value)}
                                disabled={isSaving}
                            />
                            <Form.Control.Feedback type="invalid">
                                You must be over 18 in order to take part
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Town or City<span className="form-red-star">*</span>
                    </Form.Label>
                    <Col sm={8}>
                        <InputGroup hasValidation>
                            <Form.Control
                                minLength={2}
                                required
                                type="text"
                                value={cLocation}
                                onChange={(e) => setCLocation(e.target.value)}
                                disabled={isSaving}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your nearest town or city
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Email Address<span className="form-red-star">*</span>
                    </Form.Label>
                    <Col sm={8}>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                type="email"
                                value={cEmail}
                                onChange={(e) => setCEmail(e.target.value)}
                                disabled={isSaving}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid email address
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Phone Number
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="phone"
                            value={cPhone}
                            onChange={(e) => setCPhone(e.target.value)}
                            disabled={isSaving}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Condition(s)<span className="form-red-star">*</span>
                    </Form.Label>
                    <Col sm={8}>
                        <EditableTickList as={Form.Control}
                            values={cConditions}
                            updateMethod={setCConditions}
                            isSaving={isSaving}
                            kind="conditions"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Message<span className="form-red-star">*</span>
                    </Form.Label>
                    <Col sm={8}>
                        <InputGroup hasValidation>
                            <Form.Control
                                style={{ zIndex: 0 }}
                                minLength="10"
                                required
                                value={cMessage}
                                onChange={(e) => setCMessage(e.target.value)}
                                as="textarea"
                                disabled={isSaving}
                                rows={3}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a message to our casting team, at least 10 characters long
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        Video of yourself
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="file"
                            onChange={fileSelected}
                            capture={false}
                            multiple
                            accept="video/*,video/mp4,video/x-m4v"
                            disabled={isSaving}
                            isValid={videoIsValid} />
                        <Form.Text className="form-red-star" hidden={videoIsValid}>
                            Video file must be less than 5GB in size
                        </Form.Text>
                        <Form.Text className="text-muted">
                            <p style={{ marginTop: "5px" }}>If you'd like, please send us a short 30-second video clip of you talking about your condition. We'll be in touch before using it in any programmes.</p>
                            <p style={{ marginTop: "5px" }}>Please take a quick look at our <a href="#tips">tips for filming</a> before you record your video.</p>
                        </Form.Text>
                    </Col>
                </Form.Group> */}
                <Form.Group as={Row}>
                    <Col sm={4} xs={0}></Col>
                    <Col sm={8} xs={12}>
                        <Row>
                            <Form.Label column sm={1} xs={2}>
                                <Form.Check
                                    required
                                    type="switch"
                                    id="privacy-switch"
                                    checked={agreedToPrivacy}
                                    isValid={agreedToPrivacy}
                                    onChange={(e) => setAgreedToPrivacy(e.target.checked)}
                                />
                            </Form.Label>
                            <Form.Label column sm={11} xs={10} className="lambent-casting-form-terms-and-conditions">
                                I agree to Lambent Productions Ltd. processing my personal information in accordance with their <a href="https://lambent.tv/privacy/" target="_blank" rel="noreferrer">privacy policy</a>.
                                <p className='invalid-feedback-custom' hidden={!validated || agreedToPrivacy}>Please agree before submitting the form</p>
                            </Form.Label>
                        </Row>
                    </Col>
                </Form.Group>
                <Row>
                    <Col md={4} sm={0} />
                    <Col md={8} sm={12}>
                        <Button
                            type="submit"
                            className="float-start"
                            disabled={isSaving}>
                            {
                                !isSaving ? "Submit" : "Submit"
                            }
                        </Button>
                        <Spinner
                            className="float-start align-middle"
                            hidden={!isSaving}
                            animation="border"
                            variant="secondary"
                            style={{ marginTop: "3px", marginLeft: "10px" }}
                        />
                        <div
                            className="float-start"
                            hidden={!uploadMessage}
                            style={{ marginTop: "3px", marginLeft: "8px", color: "#CCC" }}
                        >
                            <span className="align-text-top">{uploadMessage}</span>
                        </div>
                    </Col>

                </Row>
            </Form >
            <FilmingTipsModal shouldShow={showTips} onHide={() => navigate('')} />
        </>
    )
}

export default BodySecretsMainContribForm