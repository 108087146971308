import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { Container, Row } from "react-bootstrap";

import { UploadFileWithRelease, QuickPopup } from "../formElements";
import { FilmingTipsModal } from "./FilmingTips";


const Upload = (props) => {
    const { pathname, hash } = useLocation()
    const navigate = useNavigate()

    const [hasChanges, setHasChanges] = useState(false)
    const [isComplete, setIsComplete] = useState(false)

    const [contribId, setContribId] = useState(null)

    const [showTips, setShowTips] = useState(false)

    useEffect(() => {
        // remove preceding slash if it exists, so we don't get unexpected URLs
        let newPath = pathname.replace(/^\/+/, '')

        let parts = newPath.split('/')
        if (parts[1]?.length > 3 && parts[1]?.length < 6) {
            setContribId(parts[1])
        }
    }, [pathname])

    useEffect(() => {
        setShowTips(hash.includes('tips'))
    }, [hash])

    function formUpdated(changes) {
        setHasChanges(changes)
    }

    function uploadComplete(res, error) {
        if (res) {
            setIsComplete(true)
        }
    }

    return (
        <Container className="lambent-casting-container">
            <div style={{ height: "40px" }} className='no-print' />
            <Row className='no-print'>
                <h5>TAKE PART</h5>
                <h1>BODY SECRETS</h1>
                <h4 className={`crossfade-wrapper${isComplete ? null : ' cf-hidden'}`}>THANK YOU!</h4>
                <p className={`crossfade-wrapper${isComplete ? ' cf-hidden' : null}`}>Thank you for your interest in sending us a video for <b>Body Secrets: Women's Health Uncovered</b> (working title). Before you get started, please take a look at our <Link to="#tips" replace={true}>quick tips for filming</Link>, to make sure your video is the best quality it can be.</p>
            </Row>
            <hr/>
            <Row>
                <UploadFileWithRelease
                    showDescription
                    onUpdate={formUpdated}
                    finishedUpload={uploadComplete}
                    contribId={contribId}
                />
            </Row>
            <FilmingTipsModal
                className='no-print'
                shouldShow={showTips}
                onHide={() => navigate(pathname, { replace: true })}
            />

        </Container >
    )
}

export default Upload